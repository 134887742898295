import React from "react";
import "./PhotoSection.css";

function PhotoSection(props) {
  return (
    <div className="photo-image-root">
      <div className="photo-image-container">
        <img src={props.image} alt="foto bilde seksjon" className="photo-image-img" />
      </div>
    </div>
  );
}

export default PhotoSection;
