import React from "react";
import Slider from "infinite-react-carousel";
import "./Slideshow.css";

function Slideshow(props) {
  return (
    <div className="slideshow-root">
      <Slider arrows={true} arrowsBlock={true} autoplay={false} dots={true} initialSlide={0}>
        {props.images.map((item, idx) => (
          <div key={idx}>
            <img src={item} className="carousel-img" alt="Carousel Element" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Slideshow;
