import React from "react";
import "./YoutubeEmbed.css";

function YoutubeEmbed(props) {
  return (
    <div className="embed-root">
        <iframe src={`https://www.youtube.com/embed/${props.embedId}`} className="youtube" frameBorder="0" allowFullScreen title="Embedded youtube" />
    </div>
  );
}

export default YoutubeEmbed;
