import React from "react";
import "./ImageSection.css";

function ImageSection(props) {
  return (
    <div className="image-section-root">
      <div className="image-section-container">
        <div className="image-section-image-section">
          <img className="image-section-img" src={props.img} alt={props.title} />
        </div>
        <div className="image-section-text-section">
          <p className="image-section-title">{props.title}</p>
          <p className="image-section-description">{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default ImageSection;
