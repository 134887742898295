import React, { useState, useEffect } from "react";
import LogoThumbnail from "../Components/LogoThumbnail";
import AboutSection from "./AboutSection";
import Contact from "./Contact";
import Thumbnail from "../Components/Thumbnail";

function Homepage({ client }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = "*[_type == 'projects']{thumbnail} | order(_createdAt desc)";
    (async () => {
      const thumbnails = await client.fetch(query).catch(error => console.log(error));
      setData(thumbnails);
    })();
  }, [client]);

  return (
    <div>
      <LogoThumbnail img="https://holmedesign.no/images/holme_logo.png" name="Holme Design" />
      <AboutSection
        title="Welcome"
        description={[
          "Hi, and welcome to my portfolio.",
          "My name is Haavard Holme. I am a Norwegian Product designer from Oslo.",
          "Underneath you can find my portfolio.",
          "If you are interested to learn more about me. Click the link bellow."
        ]}
      />
      {data.map((elem, idx) => {
        return (
          <Thumbnail
            key={idx}
            title={elem.thumbnail.header}
            img={elem.thumbnail.imgurl}
            link={elem.thumbnail.link}
          />
        );
      })}
      <Contact />
    </div>
  );
}

export default Homepage;
