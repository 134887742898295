import React from "react";
import "./OnlyImageSection.css";

function OnlyImageSection(props) {
  return (
    <div className="only-image-root">
      <div className="only-image-container">
        <img src={props.image} alt="Bare bilde seksjon" className="only-image-img" />
      </div>
    </div>
  );
}

export default OnlyImageSection;
