import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import OnlyTitleSection from "../Components/OnlyTitleSection";
import PageHeader from "../Components/PageHeader";
import ThreeImageSection from "../Components/ThreeImageSection";
import ImageSection from "../Components/ImageSection";
import OnlyTextSection from "../Components/OnlyTextSection";
import OnlyImageSection from "../Components/OnlyImageSection";
import Slideshow from "../Components/Slideshow";
import YoutubeEmbed from "../Components/YoutubeEmbed";

function Projects({ client }) {
  const [data, setData] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const project = () => {
      return location.pathname;
    };

    const query = `*[_type == 'projects' && thumbnail.link =='${project()}']{pagesections}`;
    (async () => {
      const thumbnails = await client.fetch(query).catch(error => console.log(error));
      setData(thumbnails[0].pagesections);
    })();
  }, [client, location.pathname]);

  function findSection(param) {
    switch (param._type) {
      case "onlytitlesection":
        return <OnlyTitleSection text={param.title} />;
      case "pageheader":
        return (
          <PageHeader title={param.title} description={param.description} img={param.imgurl} />
        );
      case "threeimagesection":
        return (
          <ThreeImageSection
            title1={param.title1}
            title2={param.title2}
            title3={param.title3}
            image1={param.image1}
            image2={param.image2}
            image3={param.image3}
          />
        );
      case "imagesection":
        return (
          <ImageSection title={param.title} description={param.description} img={param.imgurl} />
        );
      case "onlytextsection":
        return <OnlyTextSection text={param.text} />;
      case "onlyimagesection":
        return <OnlyImageSection image={param.imgurl} />;
      case "slideshow":
        return <Slideshow images={param.slidepicture} />;
      case "youtubeembedsection":
        return <YoutubeEmbed embedId={param.embedId} />;
      default:
        console.log("Something is not getting recoqnized!!!!!!!!!!!!!!!!");
        return <> </>;
    }
  }

  return (
    <div className="page-root">
      <div className="page-container">
        {data.map((elem, idx) => {
          return <div key={idx}>{findSection(elem)}</div>;
        })}
      </div>
    </div>
  );
}

export default Projects;
