import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import "./Contact.css";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  projectId: "62j7u793",
  dataset: "production",
  useCdn: false // `false` if you want to ensure fresh data
});

function Contact() {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const query = `*[_type == "contact"]`;
    (async () => {
      const contactinfo = await client.fetch(query).catch(error => console.log(error));
      setData(contactinfo[0]);
      console.log(contactinfo[0]);
    })();
  }, []);
  return (
    <div className="contact-root" id="contact">
      <p className="contact-title">{data !== undefined ? data.contacttitle : <> </>}</p>
      <div className="contact-container">
        {data !== undefined ? data.description : <> </>}
        <div className="contact-images">
          {data &&
            data.clickablelinks.map((elem, idx) => {
              return (
                <a
                  key={idx}
                  href={elem.emaillink}
                  className="clickable"
                  onClick={() =>
                    ReactGA.event({
                      category: elem.gacategory,
                      action: elem.gaaction
                    })
                  }
                >
                  <img src={elem.iconimgurl} className="contact-img" alt={elem.imgalt} />
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Contact;

// Interested? You can contact me on&nbsp;
// <a href="https://www.linkedin.com/in/haavard-ingolf-holme-a7b038171/" className="clickable">
//   LinkedIn
// </a>
// , or drop me an&nbsp;
// <a href="mailto: haavard@holmedesign.no" className="clickable">
//   email
// </a>
// .
