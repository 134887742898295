import React from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import "./Thumbnail.css";

function Thumbnail(props) {
  return (
    <div className="thumbnail-root">
      <Parallax bgImage={props.img} strength={400}>
        <div className="thumbnail-container">
          <Link to={props.link}>
            <div className="inside-parallax">{props.title}</div>
          </Link>
        </div>
      </Parallax>
    </div>
  );
}

export default Thumbnail;
