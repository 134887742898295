import React, { useState, useEffect } from "react";
import "./Photography.css";
import PhotoSection from "../Components/PhotoSection";

function Photography({ client }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = `*[_type == "photography"]{photoarray}`;
    (async () => {
      const photos = await client.fetch(query).catch(error => console.log(error));
      setData(photos[0].photoarray);
    })();
  }, [client]);

  return (
    <div className="photo-root">
      <div className="photo-container">
        {data.map((elem, idx) => {
          return <PhotoSection key={idx} image={elem} />;
        })}
      </div>
    </div>
  );
}

export default Photography;
