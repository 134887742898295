import React from "react";
import "./PageHeader.css";
import { Parallax } from "react-parallax";
import "./Thumbnail.css";

function PageHeader(props) {
  return (
    <Parallax bgImage={props.img} strength={400} blur={{ min: 3, max: 3 }}>
      <div className="header-root">
        <div className="header-text-container">
          <p className="header-title">{props.title}</p>
          <p className="header-description">{props.description}</p>
        </div>
      </div>
    </Parallax>
  );
}

export default PageHeader;
