import React from "react";
import "./OnlyTextSection.css";

function OnlyTextSection(props) {
  return (
    <div className="only-text-root">
      <div className="only-text-container">
        <p className="only-text-text">{props.text}</p>
      </div>
    </div>
  );
}

export default OnlyTextSection;
