import React from "react";
import "./OnlyTitleSection.css";

function OnlyTitleSection(props) {
  return (
    <div className="only-title-root">
      <div className="only-title-container">
        <p className="only-title-text">{props.text}</p>
      </div>
    </div>
  );
}

export default OnlyTitleSection;
