import React from "react";
import "./ImageGridSection.css";

function ImageGridSection(props) {
  return (
    <div className="image-grid-root" id="about">
      {props.images.map((item, idx) => (
        <div key={idx}>
          <img src={item} className="grid-img" alt="Grid Element" />
        </div>
      ))}
    </div>
  );
}

export default ImageGridSection;
