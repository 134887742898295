import React, { useState } from "react";
import "./AboutSection.css";
import { useLocation, Link } from "react-router-dom";

function AboutSection(props) {
  const [homepage, setHomepage] = useState(true);
  let location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/") {
      setHomepage(false);
    }
  }, [location]);
  return (
    <div className="about-root" id="about">
      <div className="about-container">
        <div className="about-image-section">
          <img
            className="about-img"
            src="https://holmedesign.no/images/haavard_holme_bilde.jpg"
            alt="Haavard Portrait"
          />
        </div>
        <div className="about-text-section">
          <p className="about-title">{props.title}</p>
          {props.description.map((item, idx) => (
            <p key={idx} className="about-description">{item}</p>
          ))}
          {!homepage ? (
            <Link to="/about" className="about-link">
              <p className="about-description">More about me →</p>
            </Link>
          ) : (
            <p />
          )}
        </div>
        <p>{homepage}</p>
      </div>
    </div>
  );
}

export default AboutSection;
