import React, { useState } from "react";
import { HashRouter, Route, Link, Switch, useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import ScrollIntoView from "react-scroll-into-view";
import ReactGA from "react-ga";
import "./App.css";
import Homepage from "./Pages/Homepage";
import ScrollToTop from "./ScrollToTop";
import Photography from "./Pages/Photography";
import AboutPage from "./Pages/AboutPage";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  projectId: "62j7u793",
  dataset: "production",
  useCdn: false // `false` if you want to ensure fresh data
});

const trackingId = "UA-158853035-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, {
  debug: true
});

export function InnerApp() {
  const blackStyle = {
    backgroundColor: "black"
  };
  const [data, setData] = useState([]);
  const [photography, setPhotography] = useState(false);
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
    if (location.pathname === "/photography") {
      setPhotography(true);
    } else {
      setPhotography(false);
    }

    const query = "*[_type == 'projects']{thumbnail}";
    (async () => {
      const thumbnails = await client.fetch(query).catch(error => console.log(error));
      setData(thumbnails);
    })();
  }, [location]);
  return (
    <>
      <ScrollToTop />
      <div className="App" style={photography ? blackStyle : {}}>
        <div className="navigation">
          <div className="navigation-sub">
            <ScrollIntoView selector="#projects">
              <Link to="/" className="item">
                Projects
              </Link>
            </ScrollIntoView>
            <Link to="/about" className="item">
              About
            </Link>
            <Link to="/contact" className="item">
              Contact
            </Link>
            <Link to="/photography" className="item">
              Photography
            </Link>
          </div>
        </div>
        <Switch>
          <Route exact path="/" component={() => <Homepage client={client} />} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={AboutPage} />
          <Route path="/photography" component={() => <Photography client={client} />} />
          {data.map((elem, idx) => {
            return (
              <Route key={idx} path={elem.thumbnail.link} component={() => <Projects client={client} />} />
            );
          })}
        </Switch>
        <footer className="copyright">© Haavard I. Holme</footer>
      </div>
    </>
  );
}

function App() {
  return (
    <ParallaxProvider>
      <HashRouter>
        <InnerApp />
      </HashRouter>
    </ParallaxProvider>
  );
}

export default App;
