import React from "react";
import "./LogoThumbnail.css";

function LogoThumbnail(props) {
  return (
    <div className="root">
      <div className="container">
        <img src={props.img} className="img" alt="logo" />
        <p className="name">{props.name}</p>
        <p className="scroll">↓</p>
      </div>
    </div>
  );
}

export default LogoThumbnail;
