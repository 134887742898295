import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import "./AboutPage.css";
import OnlyTitleSection from "../Components/OnlyTitleSection";
import ImageGridSection from "../Components/ImageGridSection";
import AboutSection from "./AboutSection";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  projectId: "62j7u793",
  dataset: "production",
  useCdn: false // `false` if you want to ensure fresh data
});

function AboutPage() {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const query = `*[_type == "about"]`;
    (async () => {
      const abouts = await client.fetch(query).catch(error => console.log(error));
      setData(abouts[0]);
      console.log(abouts[0]);
    })();
  }, []);
  return (
    <div className="about-page-root" id="about">
      <div className="about-page-container">
        {data !== undefined ? (
          <AboutSection title={data.abouttitle} description={data.aboutdescription} />
        ) : (
          <> </>
        )}

        <div className="about-page-sections">
          <div className="about-page-section">
            {data !== undefined ? <ImageGridSection images={data.imagegriditems} /> : <> </>}
          </div>
        </div>
        <div className="about-page-section">
          <OnlyTitleSection text="" />
          <a
            href="https://holmedesign.no/assets/Haavard_Holme_CV_eng_2020_v2.pdf"
            onClick={() =>
              ReactGA.event({
                category: "Download CV",
                action: "Clicked link"
              })
            }
          >
            <img
              src="https://holmedesign.no/images/download_cv2.png"
              className="cv-img"
              alt="download cv"
            />
          </a>
          <OnlyTitleSection text="" />
          <OnlyTitleSection text="" />
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
