import React from "react";
import "./ThreeImageSection.css";

function ThreeImageSection(props) {
  // eslint-disable-next-line react/prop-types
  const { image1, image2, image3, title1, title2, title3 } = props;
  return (
    <div className="three-section-root">
      <div className="three-section-container">
        <div className="three-section">
          <img className="three-section-img" src={image1} alt={title1} />
          <p className="under-title">{title1}</p>
        </div>
        <div className="three-section">
          <img className="three-section-img" src={image2} alt={title2} />
          <p className="under-title">{title2}</p>
        </div>
        <div className="three-section">
          <img className="three-section-img" src={image3} alt={title3} />
          <p className="under-title">{title3}</p>
        </div>
      </div>
    </div>
  );
}

export default ThreeImageSection;
